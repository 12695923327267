<template>
<div class="flex flex-col items-center">
  <h1 class="font-medium mt-8">Login</h1>
  <div class="mt-2">
    <label> gebruikersnaam </label>
    <input v-model="username" type="text" class="border border-primary rounded block px-2">
  </div>
  <div class="mt-2">
    <label> wachtwoord </label>
    <input @keydown.enter="login" v-model="password" type="password" class="border border-primary rounded block px-2">
  </div>
  <div class="mt-4 flex justify-around">
    <div @click="login" class="bg-primary text-ternary rounded-lg px-2 py-1 text-center cursor-pointer hover:underline">Log in</div>
  </div>
</div>
</template>

<script>
import http from '../../http'

import store, { setAuth } from '../../store'

export default {
  data () {
    return {
      username: '',
      password: '',
      alreadyLoggedIn: store.auth.user
    }
  },
  methods: {
    async login () {
      const params = new URLSearchParams({
        username: this.username,
        password: this.password,
        grant_type: 'password',
        client_id: 'web'
      })

      const res = await http.post('/api/auth/token', params.toString())

      setAuth(res.data)

      this.$router.push({ path: this.$route?.query?.redirect ?? '/admin' })
    }
  }
}
</script>
